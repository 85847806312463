<template>
  <div class="nav-footer">
    <!-- 上层 -->
    <div class="f-s">
      <div class="container">
        <!-- 左侧  logo-->
        <div class="s-l">
          <!-- <img src="/imgs/logo2.png" alt="" /> -->
        </div>

        <!-- 右侧 -->
        <div class="s-r">
          <!-- 导航 -->
          <div class="f-list">
            <a href="/">网站首页</a>
            <a href="/#/ac1">行业资讯</a>
            <!-- <a href="/#/ac2">装修资讯</a> -->
            <!-- <a href="/#/ac3">房屋资讯</a> -->
            <a href="/#/xy">用户协议</a>
            <a href="/#/info">关于我们</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 下层 -->
    <div class="f-x">
      <div class="container">
        <!-- 列表 -->
        <ul>
          <li>北京欢效网络科技有限公司 </li>
          <li>公司电话：010-85895942</li>
          <!-- <li>微信公众平台：xiaheng@dysj.com    </li> -->
          <li>公司地址：北京市通州区台湖北里29号楼11层1144</li>
          <li>
            <a href="https://beian.miit.gov.cn/#/Integrated/index">网站备案：京ICP备2024063371号-1</a>
          </li>
        </ul>

        <!-- 公司名称 -->
        <h2>北京欢效网络科技有限公司</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.nav-footer {
  width: 100%;
  // margin-top: 100px;
  height: 250px;
  // height: #252e37;
  background: #252e37;
  // padding-top: 30px;
  box-sizing: border-box;

  // 上层
  .f-s {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #fff;

    .container {
      height: 100%;
      @include flex();

      // 左侧logo
      .s-l {
        width: 200px;
        height: 80px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      // 右侧导航
      .s-r {
        a {
          color: #fff;
          font-weight: bold;
          font-size: 16px;
          margin: 0 15px;
        }
      }
    }
  }

  // 下层
  .f-x {
    width: 100%;
    height: 150px;

    .container {
      height: 100%;
      // padding-top: 20px;
      box-sizing: border-box;
      @include flex();

      ul {
        li {
          color: #fff;
          font-size: 12px;
          line-height: 30px;

          a {
            color: #fff;
            font-size: 12px;
          }
        }
      }

      h2 {
        font-size: 26px;
        color: #fff;
        font-weight: 400;
      }
    }
  }
}
</style>
