<template>
  <div class="index">
    <div class="container">
      <!-- 资讯区块1 -->
      <rt1></rt1>

      <!-- 资讯区块2 -->
      <rt2></rt2>

      <!-- 区块3 st -->
      <div class="a1">
        <!-- 左侧 -->
        <div class="a1-l">
          <!-- 上层大图 -->
          <img src="/imgs/n1.jpg" />

          <div class="l-info">
            <!-- 循环项 *2 -->
            <div class="info-list" v-for="(item, index) in ac" :key="index" @click="gopay()">
              <img :src="item.img" />
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="a1-r">
          <!-- 标题 -->
          <div class="r-title">
            <h2>今日要闻 <span>TODAY NEWS</span></h2>
          </div>

          <!-- 循环项 -->
          <div class="r-item" v-for="(item, index) in ac3" :key="index" @click="gopay()">
            <h3>
              {{ item.title }}
            </h3>
            <p>
              {{ item.jj }}
            </p>
          </div>
        </div>
      </div>
      <!-- 区块3 end -->

      <!-- 区块4 -->
      <div class="ac2">
        <!-- 右侧 -->
        <div class="a2-r">
          <!-- 资讯区块1 -->
          <div class="ac22">
            <!-- 标题 -->
            <div class="a1-title">
              <h2>热门资讯</h2>
              <h4></h4>
            </div>

            <!-- 循环项 -->
            <div class="a1-item" v-for="(item, index) in this.d1" :key="index" @click="gopay()">
              <!-- 标题 -->
              <div class="title">
                {{ item.title }}
              </div>
              <!-- 中层 -->
              <div class="item-con">
                <img :src="item.img" alt="" />
                <div class="item-jj">
                  {{ item.jj }}
                </div>
              </div>
              <!-- 信息层 -->
              <div class="item-info">
                <!-- 左侧 -->
                <div class="item-l">
                  <span>{{ item.author }}</span>
                  <!-- <span> ({{ item.sub }})阅读 </span> -->
                </div>
                <!-- 右侧 -->
                <div class="item-r">收费阅读:2元/篇</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal title="请选择支付方式" btnType="1" modalType="middle" :showModal="showModal" @submit="showModal = false"
      @cancel="showModal = false">
      <template v-slot:body>
        <img style="display: flex; justify-content: center; margin: 0 auto" src="/imgs/pay2.png" />
        <h2>微信支付：2元/篇</h2>
      </template>
    </modal>
  </div>
</template>

<script>
import rt1 from "./../components/rt1.vue";
import rt2 from "./../components/rt2.vue";
import Modal from "./../components/Modal";
export default {
  components: {
    rt1,
    rt2,
    Modal,
  },
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      d1: [],
      ac: [],
      myData: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  methods: {
    goLogin() {
      alert("资讯每篇2元，点击确定购买");
      this.$router.push("/login");
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      alert("资讯每篇2元，点击确定购买");
      this.showModal = true;

      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      //   console.log(this.showModal);
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每篇3元，点击确定注册购买");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
    // 初始化请求
    init() {
      this.axios.get("/mock/info2.json").then((res) => {
        const data = res.data;
        this.ac = data.splice(0, 3);
        this.ac3 = data.splice(0, 2);
        console.log(this.ac1);
      });

      // 装修
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        this.d1 = data.splice(0, 10);
        // console.log(this.ac1);
      });
    },
  },
  mounted() {
    document.title = "北京欢效网络科技有限公司";
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.index {
  margin-top: 80px;
  background-color: #f9f9f8;

  .container {

    // 区块1
    .a1 {
      width: 100%;
      height: 500px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0;
      background: #fff;

      // 左侧
      .a1-l {
        width: 70%;
        height: 100%;

        img {
          width: 100%;
          height: 300px;
        }

        // 下层
        .l-info {
          width: 100%;
          height: 200px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          box-sizing: border-box;

          // 循环项
          .info-list {
            width: 273px;
            height: 100%;
            position: relative;
            cursor: pointer;

            &:hover {
              p {
                transform: translateY(-15px);
              }
            }

            img {
              width: 100%;
              height: 100%;
            }

            p {
              width: 203px;
              height: 50px;
              position: absolute;
              top: 36%;
              left: 11%;
              background-color: rgba(255, 255, 255, 0.8);
              text-align: center;
              line-height: 50px;
              font-size: 14px;
              transition: all 0.3s;
              font-weight: bold;
              color: #333;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }
          }
        }
      }

      // 右侧
      .a1-r {
        width: 30%;
        height: 100%;
        margin-left: 20px;
        padding: 10px 0;
        box-sizing: border-box;

        // 标题
        .r-title {
          width: 100%;
          height: 30px;
          border-bottom: 1px solid $colorZ;
          padding-left: 10px;
          line-height: 30px;
          box-sizing: border-box;

          h2 {
            font-size: 20px;
            color: #333;

            span {
              font-size: 12px;
              color: #888;
              margin-left: 5px;
            }
          }
        }

        // 循环项
        .r-item {
          width: 100%;
          height: 215px;
          margin: 20px 0;
          padding: 10px;
          box-sizing: border-box;
          cursor: pointer;

          &:hover {
            h3 {
              color: $colorZ;
            }
          }

          h3 {
            width: 100%;
            font-size: 16px;
            color: #333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }

          p {
            font-size: 12px;
            color: #888;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
            line-height: 40px;
            margin-top: 15px;
          }
        }
      }
    }

    // 资讯区块2
    .ac2 {
      width: 100%;
      // height: 800px;
      display: flex;
      justify-content: space-between;
      margin: 30px 0;

      // 右侧
      .a2-r {
        width: 100%;
        height: 100%;

        // background-color: #ff6600;
        // margin-left: 30px;
        //   资讯区块1
        .ac22 {
          width: 100%;
          // height: 600px;

          margin-top: 30px;

          // 标题
          .a1-title {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ccc;

            h2 {
              color: #333;
            }
          }

          // 循环项
          .a1-item {
            width: 100%;
            height: 200px;
            padding: 5px;
            box-sizing: border-box;
            margin: 30px 0;
            transition: all 0.2s;
            border: 2px solid #fff;

            padding: 10px 20px;
            box-sizing: border-box;

            &:hover {
              // box-shadow: 0px 0px 8px #888;
              // transform: translateY(-10px);
              background-color: #fff;
              border: 2px dashed #ccc;

              .title {
                color: $colorZ;
              }
            }

            //   标题
            .title {
              width: 100%;
              font-size: 14px;
              color: #333;
              font-weight: 400;
              cursor: pointer;
              transition: all 0.2s;

              &:hover {
                color: $colorZ;
              }
            }

            //   中层
            .item-con {
              width: 100%;
              height: 120px;
              margin: 10px 0;
              // background: #ff5544;
              display: flex;
              align-items: center;

              // 图品
              img {
                width: 200px;
                height: 100%;
              }

              .item-jj {
                margin-left: 20px;
                width: 60%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                font-size: 12px;
                color: #888;
                line-height: 30px;
              }
            }

            //   信息层
            .item-info {
              height: 30px;
              width: 100%;
              // background-color: #451215;

              // @include flex();
              display: flex;
              justify-content: space-between;
              align-items: center;

              .item-l {
                span {
                  color: #888;
                  font-size: 12px;
                  margin-right: 30px;

                  i {
                    font-size: 14px;
                    margin-right: 5px;
                  }
                }
              }

              .item-r {
                font-size: 12px;
                color: #888;
              }
            }
          }
        }
      }
    }
  }
}</style>
