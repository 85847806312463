<template>
  <div class="info">
    <!-- 导航栏 -->

    <!-- 定宽 -->
    <div class="container">
      <!-- 左侧 导航 -->
      <!-- <div class="info-1">
        <ul>
          <li class="active">关于我们</li>
          <li @click="goXy()">用户协议</li>
        </ul>
      </div> -->
      <!-- 右侧 内容 -->
      <div class="info-2">
        <h3>关于我们</h3>
        <!-- 下环线 -->
        <div class="bink"></div>

        <!-- banenr -->
        <div class="i2-banner"></div>
        <div class="i2-con">
          <h3>公司名称：北京欢效网络科技有限公司</h3>
          <!-- <h3>微信公众平台：xiaheng@dysj.com </h3> -->
          <h3>公司电话：010-85895942</h3>
          <!-- <h3>公司邮箱：wuyaqun@goyoo.com</h3> -->
          <h3>公司地址：北京市通州区台湖北里29号楼11层1144</h3>
          <p>

            北京欢效网络科技有限公司成立于2018年10月11日，注册地位于北京市通州区台湖北里29号楼11层1144，经营范围包括技术咨询、技术开发、技术推广、技术转让、技术服务；教育咨询（中介服务除外）；经济贸易咨询；文化咨询；体育咨询；公共关系服务；会议服务；工艺美术设计；电脑动画设计；企业策划、设计；设计、制作、代理、发布广告；组织文化艺术交流活动（不含营业性演出）；文艺创作；承办展览展示活动；影视策划。（市场主体依法自主选择经营项目，开展经营活动；依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）
          </p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "info",
  components: {},
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "北京欢效网络科技有限公司 ";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.info {
  width: 100%;
  background-color: #f0f0f0;
  margin-top: 80px;

  // banner
  .i-banner {
    width: 100%;
    height: 250px;
    background-color: pink;
    margin-bottom: 120px;
  }

  // 定宽布局
  .container {
    @include flex();

    // height: 800px;
    // margin-bottom: 30px;
    // 左侧
    .info-1 {
      width: 30%;
      height: 100%;
      margin-right: 20px;

      // background-color: red;
      ul {
        width: 190px;
        background-color: #fff;
        border-bottom: 2px solid #dfe1e2;
        border-radius: 3px;
        padding: 13px 15px;
        margin: 0 auto;

        li {
          width: 90%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;

          box-sizing: border-box;
          margin: 10px auto;
          cursor: pointer;
          padding-left: 20px;

          &.active {
            background-color: #3890e1;
            color: #fff;
          }
        }
      }
    }

    // 右侧
    .info-2 {
      width: 70%;
      height: 100%;
      background: #fff;
      padding-top: 20px;
      box-sizing: border-box;
      text-align: center;
      margin: 0 auto;

      h3 {
        font-size: 24px;
      }

      // 下划线
      .bink {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin: 10px auto;
      }

      // banner
      .i2-banner {
        width: 100%;
        height: 300px;
        background: url("/imgs/b3.jpg") no-repeat center;
        background-size: cover;
        margin: 30px auto;
      }

      // 文章
      .i2-con {
        p {
          font-size: 13px;
          line-height: 3.2em;
          color: #4e4b4b;
          margin: 15px auto;
          padding: 10px;
          box-sizing: border-box;
          text-align: left;
        }

        h3 {
          font-size: 18px;
          margin: 15px auto;
        }
      }
    }
  }
}
</style>
